import * as Sentry from '@sentry/nextjs';
import packageJson from './package.json';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://8877160f78dc4e058eed76dcc85e21ee@sentry.scatterlab.co.kr/11',
  tracesSampleRate: 1.0,
  release: packageJson.version
});
