function renameKeys(eventName, param) {
  const keyValues = Object.keys(param).map((key) => {
    const newKey = `custom.event.${eventName}.${key}`;
    return { [newKey]: param[key] };
  });
  return Object.assign({}, ...keyValues);
}

function debounce(callback, limit = 100) {
  let timeout;
  let items = [];
  return function debouncedCallback(item) {
    if (timeout) {
      clearTimeout(timeout);
    }
    items.push(item);
    timeout = setTimeout(() => {
      callback(items);
      items = [];
    }, limit);
  };
}

function pushEvent(payload: unknown) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.dataLayer || process.env.API_HOST !== 'https://api.blimp.space') {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer.push(payload);
}

const gtm = {
  event: ({
    eventName,
    params = {},
  }: {
    eventName: string;
    params?: { [key: string]: string };
  }) => {
    try {
      pushEvent({
        event: `custom.event.${eventName}`,
        ...renameKeys(eventName, params),
      });
      // eslint-disable-next-line no-empty
    } catch {}
  },
  ecommerceReset: () => {
    try {
      pushEvent({
        ecommerce: null,
      });
      // eslint-disable-next-line no-empty
    } catch {}
  },
  ecommerce: (
    event: string,
    payload: { [key: string]: unknown },
    eventCallback: () => void = () => {},
  ) => {
    try {
      pushEvent({
        event,
        ecommerce: payload,
        eventCallback,
      });
      // eslint-disable-next-line no-empty
    } catch {}
  },
  ecommerceViewItemList: debounce((items: { [key: string]: unknown }[]) => {
    try {
      pushEvent({ ecommerce: null });
      pushEvent({
        event: 'view_item_list',
        ecommerce: {
          items,
        },
      });
      // eslint-disable-next-line no-empty
    } catch {}
  }, 500),
};

export default gtm;
