import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import {
  createWrapper, Context, MakeStore,
} from 'next-redux-wrapper';

import paymentSlice from './payment/slice';

export const store = configureStore({
  reducer: {
    payment: paymentSlice.reducer,
  },
  devTools: process.env.NEXT_PUBLIC_NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setupStore = (context: Context): EnhancedStore => store;

const makeStore: MakeStore<typeof store> = (context: Context) => setupStore(context);

export const wrapper = createWrapper<typeof store>(makeStore);
