import preset from '@rebass/preset';
import { DefaultTheme } from 'styled-components';

import buttons from './buttons';
import fonts from './font';
import text from './text';
import box from './variants';
import landingColors from './landingColors';

import blimpTheme from 'blimp-theme.json';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const baseTheme: DefaultTheme = {
  ...preset,
  breakpoints: ['40em', '52em', '64em'],
  fonts,
  space: {
    xs: 4,
    s: 8,
    m: 16,
    l: 24,
    xl: 32,
    xxl: 54,
    xxxl: 76,
  },
  buttons,
  text,
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      color: 'text',
      backgroundColor: 'background',
    },
  },
  variants: {
    box,
  },
};

export const lightTheme: DefaultTheme = {
  ...baseTheme,
  colors: blimpTheme.colors.light,
};

export const darkTheme: DefaultTheme = {
  ...baseTheme,
  colors: blimpTheme.colors.dark,
};

export const landingTheme: DefaultTheme = {
  ...baseTheme,
  colors: landingColors,
  text: {
    ...baseTheme.text,
    default: {
      fontFamily: 'body',
      color: 'text.white',
      wordBreak: 'keep-all',
    },
    heading: {
      fontFamily: 'heading',
      color: 'text.white',
      wordBreak: 'keep-all',
    },
    caption: {
      fontFamily: 'body',
      color: 'text.white',
      wordBreak: 'keep-all',
    },
  },
};
