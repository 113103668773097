import blimpTheme from 'blimp-theme.json';

const text = {
  ...blimpTheme.typography,
  bold: {
    fontWeight: 'bold',
  },
  regular: {
    fontWeight: 'body',
  },
  default: {
    fontFamily: 'body',
    color: 'text.body',
    wordBreak: 'keep-all',
  },
  heading: {
    fontFamily: 'heading',
    color: 'text.headline',
    wordBreak: 'keep-all',
  },
  caption: {
    fontFamily: 'body',
    color: 'text.caption',
    wordBreak: 'keep-all',
  },
};

export default text;
