import packageJson from '../package.json';

const { API_HOST } = process.env;

export async function fetcher<T = unknown>(
  host: string,
  method = 'GET',
  header: { [key: string]: string } = {},
  body?: { [key: string]: unknown },
  options: {
    timeout: number;
  } = {
    timeout: 5000,
  },
): Promise<T> {
  const { timeout } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(host, {
    method,
    mode: 'cors',
    cache: 'default',
    headers: {
      'Content-Type': 'application/json',
      'P-Version': packageJson.version,
      'P-Platform': 'Web',
      'P-Vendor': process.env.NODE_ENV === 'production' ? 'Blimp' : 'Test',
      ...header,
    },
    body: !body ? undefined : JSON.stringify(body),
    signal: controller.signal,
  });
  clearTimeout(id);

  const json = await response.json();

  if (json.error) {
    throw new Error((json.error as { code: string; message: string }).message);
  }

  return json as T;
}

async function fetchJSON<T = unknown>(
  path: string,
  method = 'GET',
  body?: {
    [key: string]: unknown;
  },
): Promise<T> {
  const result = await fetcher<{ data: T }>(
    `${API_HOST}${path}`,
    method,
    {
      'Content-Type': 'application/json',
      'P-Version': packageJson.version,
      'P-Platform': 'Web',
      'P-Vendor': process.env.NODE_ENV === 'production' ? 'Blimp' : 'Test',
    },
    body,
  );

  return result.data;
}

export default fetchJSON;
