import { createSlice } from '@reduxjs/toolkit';

import PaymentAction from './actions';

import { CheckoutResponse, Customer, Product } from '@/api/CommerceAPI';

export interface PaymentState {
  status: 'INITIAL' | 'CREATING_ORDER' | 'REQUESTED' | 'FINISHED' | 'FAILED';
  errorMessage?: string;
  transactionId?: string;
  customer?: Customer;
  product?: Product;
  checkoutResponse?: CheckoutResponse;
  isLoading: boolean;
  amount?: number;
  maxAmount?: number;
}

const initialState: PaymentState = { status: 'INITIAL', isLoading: false };

const paymentSlice = createSlice({
  name: 'payment',
  reducers: {},
  extraReducers: (builder) => builder
    .addCase(PaymentAction.reset, (state) => {
      sessionStorage.removeItem('order');
      return {
        ...initialState,
        product: state.product,
        maximumAmount: state.maxAmount,
      };
    })
    .addCase(PaymentAction.resetStatus, (state) => ({
      ...state,
      status: 'INITIAL',
    }))
    .addCase(PaymentAction.requestPayment.pending, (state) => ({
      ...state,
      isLoading: true,
      errorMessage: undefined,
    }))
    .addCase(PaymentAction.requestPayment.rejected, (state, action) => ({
      ...state,
      status: 'FAILED',
      errorMessage: action.payload as string,
      isLoading: false,
    }))
    .addCase(PaymentAction.requestPayment.fulfilled, (state, action) => ({
      ...state,
      status: 'REQUESTED',
      transactionId: action.payload.transactionId,
      isLoading: false,
    }))
    .addCase(PaymentAction.confirm.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    .addCase(PaymentAction.confirm.rejected, (state, action) => ({
      ...state,
      status: 'FAILED',
      errorMessage: action.payload as string,
      isLoading: false,
    }))
    .addCase(PaymentAction.confirm.fulfilled, (state, action) => ({
      ...state,
      status: 'FINISHED',
      transactionId: action.payload.payment.orderId,
      isLoading: false,
    }))
    .addCase(PaymentAction.checkout.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    .addCase(PaymentAction.checkout.rejected, (state, action) => ({
      ...state,
      errorMessage: action.payload as string,
      isLoading: false,
    }))
    .addCase(PaymentAction.checkout.fulfilled, (state, action) => ({
      ...state,
      checkoutResponse: action.payload.checkoutResponse,
      isLoading: false,
    })),
  initialState,
});

export default paymentSlice;
