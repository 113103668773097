import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { config } from '@fortawesome/fontawesome-svg-core';

import GlobalStyle from '@/styles/GlobalStyles';
import '@/styles/fonts/pretendard/font.css';
import '@/styles/fonts/maruburi/font.css';
import useVh from '@/hooks/useVh';
import { store, wrapper } from 'features/store';

import type { AppProps } from 'next/app';

import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;

const CustomApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  useVh();

  return (
    <Provider store={store}>
      <GlobalStyle />
      <Component {...pageProps} />
    </Provider>
  );
};

export default wrapper.withRedux(CustomApp);
