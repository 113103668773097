import fetchJSON, { fetcher } from './fetchJSON';

import AnyUIAction from '@/components/ui-scheme/types/actions/AnyUIAction';
import AnyUIScheme from '@/components/ui-scheme/types/AnyUIScheme';

export interface Customer {
  name: string;
  phoneNumber: string;
}

export type ProductState =
  | 'SOLDOUT'
  | 'GETTING_READY'
  | 'IN_SALE'
  | 'HIDDEN'
  | 'SALE_ENDED';

export interface StoreHeaderResponse {
  banner: AppInstallBanner;
  categories: CategorySummary[];
}

export interface GetStoreResponse {
  featuredList: Featured[];
  products: ProductSummary[];
}

export interface GetPartnersResponse {
  banner: AppInstallBanner;
  featuredList: Featured[];
  products: ProductSummary[];
}

export interface CategorySummary {
  id: string;
  label: string;
}

export interface AppInstallBanner {
  id: string;
  content: string;
  iconUrl?: string;
  dominantColor: string;
  promotion?: Promotion;
}

export interface Featured {
  id: string;
  title: string;
  subtitle: string;
  label: string;
  thumbnailImageUrl: string;
  dominantColor: string;
  action?: AnyUIAction;
}

export interface ProductSummary {
  id: number;
  status: ProductState;
  title: string;
  description: string;
  originalPrice: number;
  salePrice: number;
  categoryIds: string[];
  coverImageUrl: string;
  spaceName?: string;
  label?: string;
  dominantColor: string;
  hasMultiplePrice: boolean;
  address?: string;
}

export interface Product extends Omit<ProductSummary, 'categoryIds'> {
  category: string;
  purchaseLabel?: string;
  ui: AnyUIScheme[];
}
export interface ProductAmount {
  id: number;
  total: number;
  sold: number;
}

type PaymentStatus =
  | 'READY'
  | 'IN_PROGRESS'
  | 'WAITING_FOR_DEPOSIT'
  | 'DONE'
  | 'CANCELED'
  | 'PARTIAL_CANCELED'
  | 'ABORTED'
  | 'EXPIRED';

export interface Payment {
  version: string;
  paymentKey: string;
  type: 'NORMAL' | 'BILLING' | 'BRANDPAY';
  orderId: string;
  orderName: string;
  mId: string;
  currency: 'KRW';
  totalAmount: number;
  balanceAmount: number;
  status: PaymentStatus;
  requestedAt: string;
  approvedAt: string;
  useEscrow: string;
  transactionKey: string;
  lastTransactionKey: string;
  suppliedAmount: number;
  vat: number;
  cultureExpense: number;
  taxFreeAmount: number;
  receipt: {
    url: string;
  };
  easyPay?: {
    amount: number;
    provider:
    | '토스페이'
    | '삼성페이'
    | '엘페이'
    | '카카오페이'
    | '페이코'
    | 'LG페이'
    | 'SSG페이';
    salePrice: number;
  };
  easyPayAmount?: number;
  easyPayDiscountAmount?: number;
  failure?: {
    code: string;
    message: string;
  };
  discount?: {
    amount: number;
  };
  card?: {
    amount: number;
    company: string;
    number: string;
    installmentPlanMonths: number;
    approveNo: string;
    useCardPoint: boolean;
    cardType: '신용' | '체크' | '기프트';
    ownerType: '개인' | '법인';
    receiptUrl: string;
    acquireStatus:
    | 'READY'
    | 'REQUESTED'
    | 'COMPLETED'
    | 'CANCEL_REQUESTED'
    | 'CANCELED';
    isInterestFree: boolean;
    interestPayer: 'BUYER' | 'CARD_COMPANY' | 'MERCHANT';
  };
}

export interface Order {
  transactionId: string;
  productId: number;
  productTitle: string;
  customer: Customer;
  checkoutOptions: Option[];
}

export interface Purchase {
  paymentKey: string;
  transactionId: string;
  amount: number;
}

export type TicketStatus =
  | 'UNUSED'
  | 'USED'
  | 'REFUNDED'
  | 'EXPIRED'
  | 'REFUND_REQUESTED'
  | 'RESERVATION_PENDING';

export interface OrderWithState {
  id: string;
  status: 'UNUSED' | 'USED' | 'REFUNDED' | 'EXPIRED';
  price: number;
  refundPrice: number;
  refundAt: null | string;
  transactionId: string;
  productId: number;
  usedAt: null | string;
  expireAt: string;
  customerName: string;
  customerContact: string;
  createdAt: string;
  productTitle: string;
  productCoverImage: string;
  refundRequestedAt: string;
}

export interface Ticket {
  id: string;
  status: TicketStatus;
  price: number;
  refundPrice: number;
  refundAt?: string;
  productId: number;
  usedAt?: string;
  expireAt: string;
  productTitle: string;
  productCoverImage: string;
  refundRequestedAt?: string;
  bookingSlotId: string;
  optionNames: string[];
  bookingDate?: string;
  bookingTime?: string;
  expectedRefundPrice: number;
  appliedRefundPolicy?: string;
}

type InstructionActionType = 'PHONE_CALL' | 'TEXT_MESSAGE' | 'OPEN_WEBVIEW';

export interface InstructionButtonData {
  icon?: string;
  label: string;
  action: InstructionActionType;
  link: string;
}

export interface TransactionDetailResponse {
  createdAt?: string;
  customer: Customer;
  id: string;
  paymentMethod: string;
  price: number;
  receiptUrl: string;
  instruction: string;
  instructionButtons: InstructionButtonData[];
  orders: Ticket[];
  refundRules: RefundRuleData[];
  pageTitle: string;
}

export interface GiftCardData {
  backgroundStartColor: string;
  backgroundEndColor: string;
  from: string;
  text: string;
  content: string;
  productId: number;
  productThumbnailUrl: string;
  expiresAt: string;
}

export interface GiftOrderWithState {
  id: string;
  productTitle: string;
  optionNames: string[]; // 옵션이 없다면 빈 배열
  bookingSlot: BookingSlotDateTime;
  status: TicketStatus;
  canChangeDate: boolean; // 일정을 변경할 수 있는지 여부 (false라면 방문 일자 변경 버튼을 보여주면 안됨)
  expectedRefundPrice: number;
  price: number;
  appliedRefundPolicy?: string; // optional
  refundAt: null | string;
  usedAt: null | string;
  expireAt: null | string;
  refundRequestedAt: null | string;
}

export interface GiftReserver {
  name: string;
  phoneNumber: string;
}

export interface RefundRuleData {
  id: string;
  rule: string;
  refundRate: string;
}

export interface AvailableDate {
  title: string;
  bookingSlotId: string;
  isSoldOut: boolean;
}

export interface BookingSlotDateTime {
  id: string;
  dateTime?: string;
}

export interface BookingSlot {
  id: string;
  title: string;
  subtitle?: string;
  price: number;
  itemIds: string[];
  inventoryId: string;
  date?: string;
  time?: string;
}

export interface BookingSlotWithAmount {
  id: string;
  amount: number;
}

export type GiftStatus = 'ACCEPTED' | 'PENDING' | 'EXPIRED' | 'UNAVAILABLE';

export interface GiftResponse {
  transactionCode?: string;
  status: GiftStatus;
  giftCard: GiftCardData;
  orders: GiftOrderWithState[];
  reserver: GiftReserver;
  refundRules: RefundRuleData[];
}

interface TicketJobResponse {
  valid: string[];
}

interface AvailableDateResponse {
  availableDates: AvailableDate[];
}

export interface RefundRequest {
  orderIds: string[];
  reason?: string;
}

export interface GetPromotionResponse {
  ui: AnyUIScheme[];
}

export interface Promotion {
  id: string;
  coverImageUrl: string;
  title: string;
  startDate: string;
  endDate: string;
  listProductIds: number[];
  isAvailable: boolean;
}

type OptionType = 'DEFAULT' | 'OPTION' | 'SCHEDULE';

type OptionGroupType = 'CALENDAR' | 'SESSION' | 'OPTION';

export type CalendarDateType = 'DISABLED' | 'WEEKDAY' | 'SATURDAY' | 'HOLIDAY';

export interface Inventory {
  id: string;
  availableAmount: number;
}

export interface OptionItem {
  id: string;
  name: string;
  additionalPrice: number;
}

export interface ScheduleSession {
  time: string;
  price: number;
  label?: string;
}

export interface SessionDate {
  date: string;
  sessions: ScheduleSession[];
}

export interface GetOptionResponse {
  optionType: OptionType;
  optionGroups: (CalendarOptionGroup | OptionGroup | SessionOptionGroup)[];
  options: OptionResponse[];
  bookingSlots: BookingSlot[];
  inventories: Inventory[];
}

export interface CalendarDate {
  date: string;
  label?: string;
  type: CalendarDateType;
}

export interface OptionPrice {
  optionItemIds: string[];
  price: number;
}

export interface CalendarOptionGroup extends BaseOptionGroup<'CALENDAR'> {
  calendarHelperText?: string;
  calendarDates: CalendarDate[];
  pricesByDate?: { [key: string]: OptionPrice[] };
}

export interface SessionOptionGroup extends BaseOptionGroup<'SESSION'> {
  sessions?: SessionDate[];
}

export interface OptionGroup extends BaseOptionGroup<'OPTION'> {
  items: OptionItem[];
  isInventoryIntegrated: boolean;
}

export interface OptionResponse {
  id: string;
  itemIds: string[];
  price: number;
  isStandardPrice: boolean;
}

export interface Option {
  amount: number;
  bookingSlot: BookingSlot;
}

interface BaseOptionGroup<Type extends OptionGroupType> {
  type: Type;
  id: string;
  name: string;
}

export interface CheckoutItem {
  id: string;
  amount: number;
}

export interface CheckoutConfirmModal {
  title: string;
  description: string;
  buttonLabel: string;
}

export interface CheckoutResponse {
  productTitle: string;
  refundRules: RefundRuleData[];
  confirmModal?: CheckoutConfirmModal;
}

export const getTotalPrice = (checkoutOptions: Option[]) => checkoutOptions.reduce(
  (acc, option) => acc + option.amount * option.bookingSlot.price,
  0,
);

export const getIsRefundable = (ticket: Ticket) => ticket.status === 'UNUSED' || ticket.status === 'RESERVATION_PENDING';

export const getIsUsable = (ticket: Ticket) => ticket.status === 'UNUSED';

// eslint-disable-next-line max-len
export const getDiscountRate = (originalPrice: number, salePrice: number) => Math.ceil(((originalPrice - salePrice) / originalPrice) * 100);

export const InternalCommerceAPI = {
  getStore: () => fetcher<{ data: GetStoreResponse }>(
    `${process.env.COMMERCE_API_HOST}/store`,
  ),
  getStoreHeader: () => fetcher<{ data: StoreHeaderResponse }>(
    `${process.env.COMMERCE_API_HOST}/store/header`,
  ),
  getProduct: (productId: number) => fetcher<{ data: Product }>(
    `${process.env.COMMERCE_API_HOST}/product/${productId}`,
  ),
  getPartners: () => fetcher<{ data: GetPartnersResponse }>(
    `${process.env.COMMERCE_API_HOST}/partners`,
  ),
};

const CommerceAPI = {
  checkProductAvailable: (productId: number) => fetcher<{
    data: { maxAmount: number; product: Product };
  }>(`/api/commerce/available?productId=${productId}`),
  // eslint-disable-next-line max-len
  requestRefund: (data: { orderIds: string[]; reason?: string }) => fetcher<{ data: TicketJobResponse }>(
    '/api/commerce/ticket/refund',
    'POST',
    {},
    data,
  ),
  useTicket: (data: { orderIds: string[]; pin: string }) => fetcher<{ data: TicketJobResponse }>(
    '/api/commerce/ticket/use',
    'POST',
    {},
    data,
  ),
  getTickets: (transactionId: string) => fetcher<{ data: TransactionDetailResponse }>(
    `${process.env.COMMERCE_API_HOST}/transaction/${transactionId}`,
    'GET',
    {},
  ),
  getTicketsFromClient: (transactionId: string) => fetcher<{ data: TransactionDetailResponse }>(
    `/api/commerce/transaction/${transactionId}`,
    'GET',
    {},
  ),
  confirm: (data: {
    productId: number;
    transactionId: string;
    paymentKey: string;
    price: number;
    customer: Customer;
    bookingSlots: BookingSlotWithAmount[];
  }) => fetcher<{ data: Payment }>('/api/commerce/confirm', 'POST', {}, data, {
    timeout: 10000,
  }),
  getGifts: (giftId: string) => fetcher<{ data: GiftResponse }>(
    `${process.env.COMMERCE_API_HOST}/gift/${giftId}`,
    'GET',
    {},
  ),
  getGiftsFromClient: (giftId: string) => fetcher<{ data: GiftResponse }>(`/api/commerce/gift/${giftId}`, 'GET', {}),
  acceptGift: (giftId: string, data: { reserver: GiftReserver }) => fetcher<{ data: string }>(
    `/api/commerce/gift/${giftId}/accept`,
    'POST',
    {},
    data,
  ),
  // eslint-disable-next-line max-len
  getAvailableDates: (giftId: string, ticketId: string) => fetcher<{ data: AvailableDateResponse }>(
    `/api/commerce/gift/${giftId}/order/${ticketId}/date`,
    'GET',
    {},
  ),
  changeBookingDate: (
    ticketId: string,
    giftId: string,
    data: { bookingSlotId: string },
  ) => fetcher<{ data: string }>(
    `/api/commerce/gift/${giftId}/order/${ticketId}/date`,
    'PUT',
    {},
    data,
  ),
  getPromotion: (shortId: string) => fetchJSON<GetPromotionResponse>(`/promotion/${shortId}`),
  getOptions: (productId: number) => fetcher<{ data: GetOptionResponse }>(
    `/api/commerce/product/${productId}/option`,
    'GET',
    {},
  ),
  // eslint-disable-next-line max-len
  getCheckout: (productId: number, items: BookingSlotWithAmount[]) => fetcher<{ data: CheckoutResponse }>(
    `/api/commerce/product/${productId}/checkout?items=${JSON.stringify(
      items,
    )}`,
    'GET',
  ),
  requestPhoneVerification: (phoneNumber: string) => fetchJSON('/user/phone-number/request-verification', 'POST', {
    phoneNumber,
  }),
  verifyPhoneNumber: (phoneNumber: string, code: string) => fetchJSON('/user/phone-number/verify', 'POST', { phoneNumber, code }),
};

export default CommerceAPI;
