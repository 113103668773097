function createPaymentsURL(baseUrl: URL): { successUrl: string; failUrl: string } {
  const url = baseUrl;
  const baseSearchParam = url.searchParams;

  baseSearchParam.set('status', 'success');
  const successUrl = `${baseUrl.origin + baseUrl.pathname}?${baseSearchParam.toString()}`;

  baseSearchParam.set('status', 'fail');
  const failUrl = `${baseUrl.origin + baseUrl.pathname}?${baseSearchParam.toString()}`;

  return {
    successUrl,
    failUrl,
  };
}

export default createPaymentsURL;
