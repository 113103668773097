const buttons = {
  capsule: {
    variant: 'text.default',
    backgroundColor: 'buttonBackground',
    backdropFilter: 'blur(20px)',
    borderRadius: '50em',
    px: ['l', 'xxl', 'xxxl'],
    py: ['m', 'l'],
    fontSize: [0, 1, 1],
    fontWeight: 'bold',
    letterSpacing: '0.08em',
    display: 'flex',
    cursor: 'pointer',
    userSelect: 'none',
    outline: 'none',

    transition: 'transform 0.3s ease-out',
    ':hover': {
      transform: 'scale(1.05)',
    },
    ':active': {
      transform: 'scale(0.95)',
    },
  },
  roundedRectangle: {
    variant: 'text.subtitle2',
    backgroundColor: 'background',
    boxShadow: 'inset 0 0 0 0.5px #BDBDBD',
    boxShadowColor: 'button.border',
    width: '100%',
    py: '1rem',
    px: '0.75rem',
    transition: 'background-color 0.15s ease-out',
    cursor: 'pointer',
    userSelect: 'none',
    outline: 'none',

    ':hover': {
      backgroundColor: 'skeleton.background',
    },
    ':active': {
      backgroundColor: 'skeleton.highlight',
    },
  },
};

export default buttons;
