import { useEffect } from 'react';

function useVh() {
  useEffect(() => {
    const setVh = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    };
    window.addEventListener('resize', setVh);
    setVh();
  }, []);
}

export default useVh;
