import { createGlobalStyle, keyframes } from 'styled-components';
import normalize from 'styled-normalize';

import { baseTheme } from './theme';

const moveLeft = keyframes`
  0% {
    transform: translate(0%, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
`;

const wave = keyframes`
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(2);
  }
`;

const waveSmall = keyframes`
  0% {
    transform: scaleY(0.5);
  }
  100% {
    transform: scaleY(1.5);
  }
`;

const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    font-family: ${baseTheme.fonts.default};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::selection {
    background: #383F38;
    color: #fff;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .move-left {
    animation: ${moveLeft} 30s linear infinite;
    overflow: visible;
  }

  .footer-animation {
    transform: translate3d(0px, calc(-300% - 35rem), 0px) !important;
  }

  .playing path,
  .stopped path {
    transition: transform 0.5s ease-in-out;
    transform-origin: center;
  }

  .playing #wave-1 {
    animation: ${wave} 1s ease-in-out infinite alternate;
  }

  .playing #wave-2 {
    animation: ${waveSmall} 1s ease-in-out infinite alternate;
    animation-delay: 0.875s;
  }

  .stopped #wave-1 {
    transform: rotate(-45deg) translate(-2px, 8px) scaleY(1);
  }

  .stopped #wave-2 {
    transform: rotate(45deg) translate(-6px,-6.5px) scaleY(0.6);
  }

  .no-click {
    pointer-events: none;
  }

  .footer-button {
    display: inline-block;
  }

  :root {
    --vh: 100%;
  }
`;

export default GlobalStyle;
