const landingColors = {
  text: {
    white: '#fff',
    black: '#2D2828',
  },
  background: '#0F0F0F',
  green: {
    700: '#6D736F',
    800: '#383F38',
  },
  beige: {
    200: '#F9F8F3',
  },
  buttonBackground: 'rgba(47, 57, 58, 0.8)',
};

export function hexToRgb(hexColor: string): string {
  const hex: string = hexColor.trim().replace('#', '');

  const rgb = hex.match(/[a-f\d]{2}/gi).map((it) => parseInt(it, 16));

  return `${rgb[0]}, ${rgb[1]}, ${rgb[2]}`;
}

export default landingColors;
