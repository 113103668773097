const box = {
  fill: {
    width: '100%',
    height: '100%',
  },
  section: {
    height: 'calc(var(--vh, 1vh) * 100)',
    width: '100vw',
    overflow: 'hidden',
    pl: 'env(safe-area-inset-left)',
    pr: 'env(safe-area-inset-right)',
    outline: 'none',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  },
};

export default box;
