import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { loadTossPayments } from '@tosspayments/payment-sdk';

import createTransactionId from './utils/createTransactionId';
import createPaymentsURL from './utils/createPaymentsURL';

import CommerceAPI, {
  BookingSlotWithAmount,
  CheckoutResponse,
  Customer,
  getTotalPrice,
  Option,
  Order,
  Payment,
  Purchase,
} from '@/api/CommerceAPI';
import gtm from '@/hooks/gtm';

const defaultErrorMessage = '결제 시도 중 문제가 발생했어요.';

// eslint-disable-next-line max-len
const getQuantity = (checkoutOptions: Option[]) => checkoutOptions.reduce((acc, option) => acc + option.amount, 0);

function isValidPurchase({
  purchase,
  order,
}: {
  purchase: Purchase;
  order: Order;
}) {
  return (
    purchase.transactionId === order.transactionId
    && purchase.amount === getTotalPrice(order.checkoutOptions)
  );
}

function isValidPayment({
  payment,
  purchase,
  order,
}: {
  payment: Payment;
  purchase: Purchase;
  order: Order;
}) {
  return (
    isValidPurchase({ purchase, order })
    && payment.totalAmount === getTotalPrice(order.checkoutOptions)
    && payment.orderId === purchase.transactionId
    && payment.paymentKey === purchase.paymentKey
  );
}

const PaymentAction = {
  reset: createAction('reset'),
  resetStatus: createAction('resetStatus'),
  requestPayment: createAsyncThunk<
  {
    transactionId: string;
    customer: Customer;
  },
  {
    productId: number;
    productTitle: string;
    customer: Customer;
    checkoutOptions: Option[];
  }
  >(
    'requestPayment',
    async (
      {
        productId, productTitle, customer, checkoutOptions,
      },
      { rejectWithValue, dispatch },
    ) => {
      try {
        const transactionId = createTransactionId();
        const order: Order = {
          transactionId,
          productId,
          productTitle,
          customer,
          checkoutOptions,
        };
        sessionStorage.setItem('order', JSON.stringify(order));

        const { successUrl, failUrl } = createPaymentsURL(
          new URL(window.location.origin + window.location.pathname),
        );

        dispatch(
          PaymentAction.openPayment({
            failUrl,
            successUrl,
            transactionId,
            transactionName: productTitle,
            price: getTotalPrice(order.checkoutOptions),
            customerName: customer.name,
          }),
        );

        return { transactionId, customer };
      } catch (err) {
        return rejectWithValue(defaultErrorMessage);
      }
    },
  ),
  openPayment: createAsyncThunk<
  void,
  {
    failUrl: string;
    successUrl: string;
    transactionId: string;
    transactionName: string;
    customerName: string;
    price: number;
  }
  >(
    'openPayment',
    async ({
      successUrl,
      failUrl,
      price,
      transactionId,
      transactionName,
      customerName,
    }) => {
      const tossPayments = await loadTossPayments(
        process.env.NEXT_PUBLIC_CLIENT_KEY,
      );
      tossPayments.requestPayment('카드', {
        amount: price,
        orderId: transactionId,
        orderName: transactionName,
        customerName,
        successUrl,
        failUrl,
      });
    },
  ),
  confirm: createAsyncThunk<{ payment: Payment }, { purchase: Purchase }>(
    'confirm',
    async ({ purchase }, { rejectWithValue }) => {
      try {
        const order: Order = JSON.parse(sessionStorage.getItem('order'));

        if (!order || !isValidPurchase({ purchase, order })) {
          throw new Error();
        }

        const { data: payment } = await CommerceAPI.confirm({
          productId: order.productId,
          transactionId: purchase.transactionId,
          paymentKey: purchase.paymentKey,
          price: purchase.amount,
          customer: order.customer,
          bookingSlots: order.checkoutOptions.map((option) => ({
            id: option.bookingSlot.id,
            amount: option.amount,
          })),
        });

        if (!isValidPayment({ payment, order, purchase })) {
          throw new Error('Invalid Payment');
        }
        await new Promise<void>((resolve) => {
          gtm.ecommerceReset();
          gtm.ecommerce(
            'purchase',
            {
              transaction_id: purchase.transactionId,
              value: getTotalPrice(order.checkoutOptions),
              tax: purchase.amount * 0.1,
              shipping: 0,
              currency: 'KRW',
              items: [
                {
                  item_id: order?.productId,
                  item_name: order?.productTitle,
                  currency: 'KRW',
                  quantity: getQuantity(order.checkoutOptions),
                  item_list_name: '전체 상품 목록',
                  item_list_id: 'all_products',
                  index: 0,
                },
              ],
            },
            resolve,
          );
        });

        sessionStorage.removeItem('order');
        sessionStorage.removeItem('checkoutProps');
        return { payment };
      } catch (err) {
        return rejectWithValue(defaultErrorMessage);
      }
    },
  ),
  checkout: createAsyncThunk<
  {
    checkoutResponse: CheckoutResponse;
  },
  {
    productId: number;
    items: BookingSlotWithAmount[];
  }
  >('checkout', async ({ productId, items }, { rejectWithValue }) => {
    try {
      const { data } = await CommerceAPI.getCheckout(productId, items);

      return {
        checkoutResponse: data,
      };
    } catch (err) {
      return rejectWithValue(defaultErrorMessage);
    }
  }),
};

export default PaymentAction;
